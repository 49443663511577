@font-face {
  font-family: 'Bungee';
  src: url('fonts/Bungee-Regular.woff2') format('woff2'),
      url('fonts/Bungee-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'DistilleryDisplay';
  src: url('fonts/DistilleryDisplay.woff2') format('woff2'),
      url('fonts/DistilleryDisplay.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

:root {
  --red: #ed1c24;
  --green: #026036;
  --light-yellow: #fffbd8;
  --yellow: #ffd42a;


  --question-bg: #1e90ff;
  --question-color: #1e90ff;
  --main-bar-bg: #ed1c24cc;
  --main-bar-color: #ffffff;
  --popup-bg: #fffbd8;
  --popup-border: #ed1c24;
  --popup-color: #000;
  --popup-heading: var(--green);
  --popup-heading: var(--green);
  --btn-border: #ed1c24;
  --input-border: #ed1c24;
  --heading-color: var(--green);
  --answer-bg: var(--green);
}

input {
  color: var(--popup-color);
}

.popup_content a {
  color: var(--popup-color);
}

.bungee {
  font-family: DistilleryDisplay;
  color: var(--heading-color);
  /* text-shadow: rgb(0, 255, 255) 0.03em -0.03em 0px, rgb(255, 0, 255) -0.03em 0.03em 0px; */
}


.App {
  height: 100%;
  position: absolute;
  width: 100%;

}

.header {
  position: absolute;
  top: 0;
  background: var(--main-bar-bg);
  width: 100%;
  border-radius: 0 0 20px 20px;
  display: flex;
  padding: 20px;
  align-items: flex-end;
}

.logo {
  background-image: url('images/dt-logo-white.png');
  background-size: contain;
  background-repeat: no-repeat;
  width: 70px;
  height: 50px;
}

.progress-container {
  flex: 1;
  text-align: center;
  color: var(--main-bar-color);
}

.progress-text {
  margin: 0 0 5px;
  padding-right: 40px;
}

.progress-wrap {
  display: flex;
  padding: 0 10px;
}

.progress-number {
  margin-left: 10px;
}

.progress {
  flex: 1;
  position: relative;
  height: 20px;
  border: 2px solid #0000;
  border-radius: 20px;
  padding: 1px;
  overflow: hidden;
  box-shadow: 0 0 0 2px var(--main-bar-color);
}

.progress::before {
  position: absolute;
  content: '';
  background: var(--main-bar-color);
  width: 100%;
  height: 100%;
  top: 0;
  left:0;
}

.progress-bar {
  background-color: #8dc740;
  position: absolute;
  top:0px;
  bottom:0px;
  left:0px;
}

.scan-btn {
  height: 50px;
  width: 50px;
  border-radius: 5px;
  border: none;
  background-image: url('images/scan.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #fff;
  cursor: pointer;
}

.info-btn {
  height: 50px;
  width: 50px;
  border-radius: 5px;
  border: none;
  background-image: url('images/icon_info.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #ffffff00;
 /* background-image: linear-gradient(to right,#672784, #3d1754); */
  cursor: pointer;
  color: white;
  margin-right: 5px;
  font-size: 30px;
  font-weight: bold;
}

.btn {
  min-height: 50px;
  width: 180px;
  padding: 0;
  background: none;
  border: none;
  cursor: pointer;
}

.btn img {
  width: 100%;
}

.btn-blue, .btn-pink {
  text-transform: uppercase;
  border: 3px solid var(--btn-border);
  border-radius: 10px;
  padding: 0 20px;
  min-width: 140px;
  width: auto;
  font-family: DistilleryDisplay;
  color: var(--heading-color);
  font-size: 30px;
  /* text-shadow: rgb(0, 255, 255) 0.03em -0.03em 0px, rgb(255, 0, 255) -0.03em 0.03em 0px; */
  transition: all 0.5s;
}

.btn-blue:hover, .btn-pink:hover {
  transform: scale(1.05);
}

/* .btn-blue {
  background-color: #10427A;
  background-image: linear-gradient(to right, #10427A , #071B2D);
}

.btn-pink {
  background-color: #B33184;
  background-image: linear-gradient(to right, #B33184 , #5B1A47);
} */

.scanner-wrap {
  position: absolute;
  height: 100%;
  width: 100%;
  top:0;
  left:0;
  background: #000;
}


.scanner, .scanner-overlay, .scanner-overlay svg {
  position: absolute;
  height: 100%;
  width: 100%;
  top:0;
  left:0;
}

.scanner-overlay button {
  position: absolute;
  top: 10px;
  left: 10px;
  height: 30px;
  width: 30px;
  border-radius: 30px;
  border: 3px solid white;
  background-color: #672784;
  background-image: linear-gradient(to right,#672784, #3d1754);
  cursor: pointer;
  color: white;
  font-weight: bold;
  padding: 0;
  text-align: center;
}


.question {
  text-align: center;
  position: absolute;
  width: 100%;
  bottom: 0;
  color: #fff;
  background: #000d;
  padding: 15px;
  animation: slideup 0.8s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;

  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  box-shadow: 0px 0px 10px 3px #363636;
  border-radius: 10px 10px 0 0;

  max-height: 100%;
  overflow-y: auto;
}

.question_img {
  margin-bottom: 10px;
  margin-top: 10px;
  max-width: 100%;
  max-width: calc(100% - 10px);
}

.question h2 {
  font-family: 'DistilleryDisplay';
  margin: 0 0 15px;
  width: 100%;
}
.question h4 {
  margin: -15px 0 10px;
  width: 100%;
}

.question p {
  width: 100%;
  margin: 0 0 10px;
}

.question-answers {
  width: 100%;
  max-width: 600px;
  margin-top: -10px;
}

.btn-answer {
  padding: 10px;
  font-size: 16px;;
  color: #fff;
  background-color: var(--answer-bg);
  border: 1px solid transparent;
  border-radius: 15px;
  display: flex;
  width: 100%;
  max-width: 768px!important;
  justify-content: center;
  margin-top: 8px;
  box-shadow: 0 10px 20px #00000030,0 6px 6px #0000003b!important;
  font-weight: 700;
  align-items: center;
  cursor: pointer;
}


.popup-wrap {
  display: none;
}

.popup-wrap.show {
  display: flex;
  position: fixed;
  padding:15px;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000000aa;
  justify-content: center;
  align-items: center;
}

.popup {
  width: 100%;
  max-width: 340px;
  color:var(--popup-color);
  background: var(--popup-bg);
  padding: 20px;
  box-shadow: 0px 0px 40px 0px #000;
  border: 5px solid var(--popup-border);
  border-radius: 20px;
  text-align: center;
  max-height: 100vh;
  max-height: calc(100vh - 20px);
  overflow-y: auto;
}

.popup_image {
  margin-bottom: 15px;
  height: 130px;
  max-width: 100%;
}

.popup_image_bottom {
  margin-top: 15px;
  height: 28px;
  max-width: 100%;
  display: block;
  margin: 15px auto 0;
}

.popup_title {
  margin: 0 0 15px;
}

.popup_content {
  margin: 0 0 15px;
}

.info-popup .popup_title {
  font-size: 25px;
  font-family: DistilleryDisplay;
  color: var(--heading-color);
  /* text-shadow: rgb(0, 255, 255) 0.03em -0.03em 0px, rgb(255, 0, 255) -0.03em 0.03em 0px; */
}

.popup--correct .popup_title {
  font-family: DistilleryDisplay;
}

.info-popup .popup_content p{
    margin: 0 0 10px;
}

.info-img {
  height: 30px;
}

.info-img--find {
  
}

.info-img--scan {

  transform: rotate(60deg);
}

.info-img--email {
  height: 26px;
}


.input-row {
  margin-bottom: 5px;
  display: flex;
}

.input-row label {
  padding-right: 10px;
  width: 100px;
  text-align: right;
}

.input-row input{
  margin-bottom: 5px;
  background: none;
  border: 1px solid var(--input-border);
  padding: 5px;
}











@keyframes slideup {
  0% {
    transform: scale(1) translateY(0px);
    opacity: 0;
    /* box-shadow: 0 0 0 rgba(241, 241, 241, 0); */
  }
  1% {
    transform: scale(1) translateY(100%);
    opacity: 0;
    /* box-shadow: 0 0 0 rgba(241, 241, 241, 0); */
  }
  100% {
    transform: scale(1) translateY(0%);
    opacity: 1;
    /* box-shadow: 0 0 500px rgba(241, 241, 241, 0); */
  }
}


@keyframes anvil {
  0% {
    transform: scale(1) translateY(0px);
    opacity: 0;
    /* box-shadow: 0 0 0 rgba(241, 241, 241, 0); */
  }
  1% {
    transform: scale(0.96) translateY(10px);
    opacity: 0;
    /* box-shadow: 0 0 0 rgba(241, 241, 241, 0); */
  }
  100% {
    transform: scale(1) translateY(0px);
    opacity: 1;
    /* box-shadow: 0 0 500px rgba(241, 241, 241, 0); */
  }
}




.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  height: 100%;
  /* color: white; */
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
